#herobanner {
  background: url(./assets/saxar.jpg) no-repeat center center;
  background-size: cover;
  z-index: -1;

  #full-screen-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;

    .metadata {
      text-align: center;
      margin-top: 120px;

      @media screen and (min-device-width: 900px) {
        width: 50%;
        margin-left: 25%;
      }

      @media screen and (max-device-width : 812px)  {
        width: 100%;
      }

      a {
        text-decoration: none;
      }

      h1 {
        font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 90px;
        line-height: 1;
        margin: 35px 0 0 0;
        text-transform: capitalize;
        font-variant: small-caps;

        @media screen and (max-device-width : 812px)  {
          font-size: 70px;
        }
      }

      p {
        font-size: 24px;
        line-height: 1.2;
        font-family: 'Runda', 'helvetica', sans-serif;
        font-weight: 300;
        font-style: normal;
        margin: 15px auto;
        width: 95%;
      }

      div#bookBtn {
        background-color: #fff;
        color: #000;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 300;
        margin: 20px auto;
        width: 80%;
      }

      p#mobile-contact {
        display: none;

        @media screen and (max-device-width : 812px)  {
          display: block;
        }

        a {
          color: white;
        }
      }
    }
  }
}
