header {
  color: #fff;
  float: none;
  z-index: 999;
  background-color: #000;
  flex-direction: row;
  place-content: center space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 10px 15px;
  display: flex;
}

@media screen and (device-width <= 812px) {
  header {
    text-align: center;
    justify-content: center;
    padding: 10px 0;
  }
}

header img#logo {
  height: 80px;
  max-height: 100%;
}

@media screen and (device-width <= 812px) {
  header img#logo {
    float: none;
    height: 80px;
  }
}

header ul#linkList {
  margin: 0;
  list-style: none;
}

@media screen and (device-width <= 812px) {
  header ul#linkList {
    display: none;
  }
}

header ul#linkList li {
  float: left;
  margin: 0 5px;
  padding: 0 5px;
}

header ul#linkList li a {
  color: #fff;
  text-decoration: none;
}

@media screen and (device-width <= 812px) {
  header ul#linkList li a {
    margin-left: 20px;
    font-size: 22px;
  }
}

#herobanner {
  z-index: -1;
  background: url("saxar.08c1330c.jpg") center / cover no-repeat;
}

#herobanner #full-screen-image {
  color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#herobanner #full-screen-image .metadata {
  text-align: center;
  margin-top: 120px;
}

@media screen and (device-width >= 900px) {
  #herobanner #full-screen-image .metadata {
    width: 50%;
    margin-left: 25%;
  }
}

@media screen and (device-width <= 812px) {
  #herobanner #full-screen-image .metadata {
    width: 100%;
  }
}

#herobanner #full-screen-image .metadata a {
  text-decoration: none;
}

#herobanner #full-screen-image .metadata h1 {
  text-transform: capitalize;
  font-variant: small-caps;
  margin: 35px 0 0;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}

@media screen and (device-width <= 812px) {
  #herobanner #full-screen-image .metadata h1 {
    font-size: 70px;
  }
}

#herobanner #full-screen-image .metadata p {
  width: 95%;
  margin: 15px auto;
  font-family: Runda, helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
}

#herobanner #full-screen-image .metadata div#bookBtn {
  color: #000;
  text-transform: uppercase;
  background-color: #fff;
  width: 80%;
  height: 60px;
  margin: 20px auto;
  font-size: 30px;
  font-weight: 300;
  line-height: 60px;
}

#herobanner #full-screen-image .metadata p#mobile-contact {
  display: none;
}

@media screen and (device-width <= 812px) {
  #herobanner #full-screen-image .metadata p#mobile-contact {
    display: block;
  }
}

#herobanner #full-screen-image .metadata p#mobile-contact a {
  color: #fff;
}

content {
  color: #000;
  background-color: #fff;
}

content section {
  clear: both;
  flex-flow: wrap;
  place-content: center space-evenly;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

@media screen and (device-width >= 900px) {
  content section p, content section table {
    width: 60% !important;
  }
}

@media screen and (device-width <= 812px) {
  content section p, content section table {
    width: 100%;
    padding: 0 20px;
  }
}

content section h2 {
  text-align: center;
  color: #333;
  font-family: Runda, helvetica, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
  margin-top: 40px !important;
}

@media screen and (device-width <= 812px) {
  content section h2 {
    font-size: 45px;
  }
}

content section p {
  font-family: Runda, helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
}

content section p#terms {
  text-align: center;
  font-size: 15px;
}

content section table td {
  width: 70%;
}

content section table td span.description {
  display: block;
}

content section.wide {
  width: 100%;
}

content section.wide div {
  text-align: center;
}

content section.wide div.portrait {
  background-color: #e8e8e8;
  background-size: cover;
  border-radius: 75px;
  width: 150px;
  height: 160px;
}

content section.wide.logos img {
  max-width: 300px;
  margin-bottom: 20px;
}

footer {
  color: #fff;
  text-align: center;
  background-color: #444;
  width: 100%;
  padding: 20px 0;
  line-height: 2;
}

footer a {
  color: #fff;
}

html, body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-family: Runda, helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden;
}

h1, h2, h3 {
  clear: both;
  width: 100% !important;
}

a {
  color: #000;
  font-weight: bold;
}

a:hover {
  color: #e8e8e8;
}
/*# sourceMappingURL=index.a736ebe5.css.map */
