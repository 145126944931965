header {
  height: 80px;
  width: 100%;
  background-color: #000;
  padding: 10px 15px;
  color: #fff;
  float:none;
  z-index: 999;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media screen and (max-device-width : 812px)  {
    justify-content: center;
    text-align: center;
    padding: 10px 0;
  }

  img#logo {
    max-height: 100%;
    height: 80px;

    @media screen and (max-device-width : 812px)  {
      float: none;
      height: 80px;
    }
  }

  ul#linkList {
    margin: 0;
    list-style: none;

    @media screen and (max-device-width : 812px)  {
      display:none;
    }

    li {
      float: left;
      padding: 0 5px;
      margin: 0 5px;

      a {
        color: #fff;
        text-decoration: none;

        @media screen and (max-device-width : 812px)  {
          font-size: 22px;
          margin-left: 20px;
        }
      }
    }
  }
}
