footer {
  background-color: #444;
  color: #fff;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  line-height: 2;

  a {
    color: #fff;
  }
}
