@import "styles/header.scss";
@import "styles/herobanner.scss";
@import "styles/content.scss";
@import "styles/footer.scss";

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Runda', 'helvetica', sans-serif;
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  overflow-x: hidden;
}

h1, h2, h3 {
  clear: both;
  width: 100% !important;
}

a {
  color: #000;
  font-weight: bold;

  &:hover {
    color: #e8e8e8;
  }
}

