content {
  background-color: #fff;
  color: #000;

  section {
    margin-bottom: 50px;
    clear: both;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;

    p, table {
      @media screen and (min-device-width: 900px) {
        width: 60% !important;
      }

      @media screen and (max-device-width : 812px)  {
        width: 100%;
        padding: 0 20px;
      }
    }

    h2 {
      font-family: 'Runda', 'helvetica', sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 55px;
      line-height: 1;
      text-align: center;
      color: #333333;
      margin-top: 40px !important;

      @media screen and (max-device-width : 812px)  {
        font-size: 45px;
      }
    }

    p {
      font-size: 24px;
      line-height: 1.2;
      font-family: 'Runda', 'helvetica', sans-serif;
      font-weight: 300;
      font-style: normal;

      &#terms {
        font-size: 15px;
        text-align: center;
      }
    }

    table {
      td {
        width: 70%;

        span.description {
          display: block;
        }
      }
    }

    &.wide {
      width: 100%;

      div {
        text-align: center;
      }

      div.portrait {
        width: 150px;
        height: 160px;
        background-color: #e8e8e8;
        border-radius: 75px;
        background-size: cover;
      }

      &.logos {
        img {
          max-width: 300px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
